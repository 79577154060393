import OKEXChainClient, { crypto } from "@okexchain/javascript-sdk";
import _ from "lodash";

const isMainnet = true;

const serverUrlTest = "https://exchaintest.okexcn.com";
// const serverUrlMainnet = "https://okex-rpc.nebulas.io";
// const serverUrlMainnet = "https://exchaintmrpc.okex.org";

const serverUrlMainnet = "https://exchainrpc.okex.org";

const clientConfigTest = {
  chainId: "exchain-65",
  relativePath: "/okexchain-test/v1",
  isMainnet,
};

const clientConfigMainnet = {
  chainId: "exchain-66",
  relativePath: "/okexchain/v1",
  isMainnet,
};

const nativeDenom = "okt";

const defaultMainnetFee = {
  amount: [
    {
      amount: "0.004000000000000000",
      denom: nativeDenom,
    },
  ],
  gas: "4000000",
};

let isPrivateKeyOldAddress = false;

export const defaultFee = defaultMainnetFee;

export const okClient = new OKEXChainClient(
  isMainnet ? serverUrlMainnet : serverUrlTest,
  isMainnet ? clientConfigMainnet : clientConfigTest
);

// all alliance nodes validators
export const originValidators = [
  "exvaloper1vsjcts3ga8dgf6nj2q7vmlrnu5en4cneaskg9w",
  "exvaloper188dhgmaq8cka2yczzjfzsw0nely6y8uap4u5kg",
  "exvaloper1xaxvu9wxr8szym3aqdesvqq968y2tf53l8fyak",
];

// 用于统计
export const validatorsCommunity = [
  "exvaloper188dhgmaq8cka2yczzjfzsw0nely6y8uap4u5kg",
  "exvaloper1vsjcts3ga8dgf6nj2q7vmlrnu5en4cneaskg9w",
  "exvaloper1xaxvu9wxr8szym3aqdesvqq968y2tf53l8fyak",
  "exvaloper1pc864zshkvsgc724k5jffcjsrmhz63m6gwrrhh",
  "exvaloper1klcqq056hndfch7yew6fkl6tyrdr07qxv60g90",
  "exvaloper1a9893mfvk9x80x209jhpvl0hm24djxqhr3jwuf",
];

// all alliance nodes validators
export const validators = [
  "exvaloper1ja9xngm4zh0t442mse73ll30p7dczd49s88n2x",
  "exvaloper1ka92ujcwh6hyyeu4tymzy3dedgxplt4dtsr7xh",
  "exvaloper1zza3jrylyecrtuh0p9ts2xauzsefuvwa4l9fsx",
  "exvaloper1qva0ejf0t943x6rt824gwmvtjgec9cjrut5wn8",
  "exvaloper19wln93k3faq7vkqzlc9gljr3ey5fljt93jfxsy",
  "exvaloper195ez67wmhprwrru34gvttyd8ttpl7edx3px8ua",
  "exvaloper1q9nct2gska2yutx24starv6s63xz022fdwdgzv",
  "exvaloper1zxthrcdcecfe5ss4tal0tq30hzel2lks6psuhm",
  "exvaloper1s6nfs7mlj7ewsskkrmekqhpq2w234fcz5uq4m5",
  "exvaloper18au05qx485u2qcw2gvqsrfh29evq77lm9u2jwr",
  "exvaloper1xkl5agjzqnjnptyat2dng2asmx8g5kllg7xamv",
  "exvaloper1fymxn4gazxzjdfvwvr0ccnrnjpwmj0r9vw3t2y",
  "exvaloper1m569cfenudxemegcf4mmykhugnslhdv0xhvcj6",
  "exvaloper1tkwxgcpvptua0q0h5tn0at58ufnjdue7xp9jh4",
  "exvaloper1ygcvtcqxl82xvzrq25dymam434k3nnc8kwvrkm",
  "exvaloper1c34s7lc7ec8gs9xrtxeh0j2wjaam25c3g06xp3",
  "exvaloper1508d7eq592kg2lh9d46xvv3r4sm7gm8w0nrejk",
  "exvaloper19e6edpu97d6w2t5dlp7lph2fkdja0lvlj8ngk0",
  "exvaloper1ucmx6vvtrwam9pg20fnwmy9z80uhchyxqn67wq",
  "exvaloper1g3a6vtau2k93n4tqgqnrggeu3qa4x20rgs4x4s",
  "exvaloper1tat4lam8wjqmeax9mv4s584vu2mp7c0cgvlajl",
  "exvaloper188dhgmaq8cka2yczzjfzsw0nely6y8uap4u5kg",
  "exvaloper1vsjcts3ga8dgf6nj2q7vmlrnu5en4cneaskg9w",
  "exvaloper1xaxvu9wxr8szym3aqdesvqq968y2tf53l8fyak",
  "exvaloper1pc864zshkvsgc724k5jffcjsrmhz63m6gwrrhh",
  "exvaloper1klcqq056hndfch7yew6fkl6tyrdr07qxv60g90",
  "exvaloper1a9893mfvk9x80x209jhpvl0hm24djxqhr3jwuf",
];

// origin proxy address, need filter
export const originProxyAddress = [
  "ex18eu8mr89z5l9g5xe2lt3hg20q7zd7xk6wvgl8s",
  "ex1zttaewld84w3j8y98mtw34xtksw8mygz976fa2",
  "ex1r0ckjln69kv0ud57vhhlw3lcma7k5hs88h9nse",
  "ex18jyvkmthjhf9agucpj8skkcq68h0d0lw4yl50k",
  "ex18eu8mr89z5l9g5xe2lt3hg20q7zd7xk6wvgl8s",
  "ex1fn2tkt6k2y05cj909clsa0xfy8skz4wrhwwgya",
  "ex1dl30pugqp6ms787jfm4a3ru89gs9t0r6kpk0rd",
  "ex1w4yxs3sxcztz4eurkp6h60jnk46vw0mcckke38",
];

// get private key from mnemonic
export const getPrivateKeyFromMnemonic = async (mnemonic, cointype = "60") => {
  try {
    const privateKey = crypto.getPrivateKeyFromMnemonic(mnemonic, cointype);
    return privateKey;
  } catch (err) {
    throw err;
  }
};

// get private key from keystore
export const getPrivateKeyFromKeyStore = async (keystore, password) => {
  try {
    const privateKey = crypto.getPrivateKeyFromKeyStore(keystore, password);
    return privateKey;
  } catch (err) {
    throw err;
  }
};

export const getPrivateKeyOldAddress = () => {
  return isPrivateKeyOldAddress;
};

// get address from private key
export const getAddressFromPrivateKey = (privateKey) => {
  try {
    let address = crypto.getAddressFromPrivateKey(privateKey);
    if (isPrivateKeyOldAddress) {
      address = crypto.getAddressFromPrivateKeyLegacy(privateKey);
    }
    console.log("address", address);

    return address;
  } catch (err) {
    console.log("get address error");
    throw err;
  }
};

// get ok account from private key
export const getAccountFromPrivateKey = async (
  privateKey,
  isPrivateKeyOldAddress = false
) => {
  try {
    // get ok account instance
    const prefix = "ex";
    const okAcc = await okClient.setAccountInfo(
      privateKey,
      prefix,
      isPrivateKeyOldAddress
    );
    console.log("okAccount", okAcc);
    return okAcc;
  } catch (err) {
    console.log("get account error");
    throw err;
  }
};

// get okt balance from address
export const getOKTbalanceFromAddress = async (address) => {
  try {
    const coins = await okClient.getBalance(address);
    // get okt
    const oktBalance = _.find(coins, { denom: "okt" });

    if (oktBalance) {
      console.log("okt balance", oktBalance.amount);
      return Number(oktBalance.amount);
    }
  } catch (err) {
    console.log("get balance err", err);
    throw err;
  }
};

export const setAddressToLocal = (addr) => {
  localStorage.setItem("address", addr);
};

export const getAddressFromLocal = () => {
  return localStorage.getItem("address");
};

export const setKeystoreDataToLocal = (data) => {
  localStorage.setItem("keystore", data);
};

export const getKeystoreDataFromLocal = () => {
  return localStorage.getItem("keystore");
};

export const clearLocalData = () => {
  localStorage.removeItem("address");
  localStorage.removeItem("keystore");
};
