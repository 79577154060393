import React from "react";
import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import Dashboard from '../partials/Dashboard'
import RewardDetail from "../partials/RewardDetail";
import Alliance from '../partials/Alliance'
import Footer from "../partials/Footer";

import { ToastContainer } from 'react-toastify';


function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <HeroHome />

        <Dashboard />

        <RewardDetail />

        {/* 节点联盟 */}
        <Alliance />

        {/* 退币申请 */}
        {/* <Newsletter /> */}
      </main>

      {/*  Site footer */}
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Home;
