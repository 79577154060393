import React, { useState } from 'react';
import Modal from '../utils/Modal';

function HeroHome() {

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section className="relative">

      {/* Illustration behind hero content */}
      <div className="absolute bottom-0 transform -translate-x-1/2 pointer-events-none left-1/2" aria-hidden="true">
        <svg width="1360" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl px-4 mx-auto sm:px-6">

        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-10">

          {/* Section header */}
          <div className="pb-12 text-center md:pb-8">
            <h1 className="mb-4 text-5xl font-extrabold tracking-tighter leading-tighter" data-aos="zoom-y-out">OKC
            &nbsp;
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-teal-400">节点联盟</span>
            </h1>
            
            <div className="max-w-xl mx-auto">
              <p className="mb-2 text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">最优策略 最佳收益</p>

              <p className="mb-8 text-gray-500 text-md" data-aos="zoom-y-out" data-aos-delay="150">星云实验室提供技术支持和运维</p>

              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                {/* <div>
                  <a className="w-full mb-4 text-white bg-blue-600 btn hover:bg-blue-700 sm:w-auto sm:mb-0" href="https://docs.qq.com/form/page/DQnJzY0llR2ZqRmds?_w_tencentdocx_form=1#/fill"
                  target="__blank"
                  >我要投票</a>
                </div> */}
                <div>
                  <a className="w-full text-gray-900 bg-gray-300 btn hover:bg-gray-400 sm:w-auto sm:ml-4" href="https://www.yuque.com/becky-sltzq/novanode/wpxzc7"
                  target="__blank">如何投票</a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
}

export default HeroHome;