import React from "react";

const nodeList = [
  {
    name: "nas",
    address: "okexchainvaloper188dhgmaq8cka2yczzjfzsw0nely6y8ua3ad0du"
  },
  {
    name: "chainup",
    address:"okexchainvaloper1vlzgq74y6hm9crhkkhdjy77uvyqa0zdu3c6tmx"
  },
  {
    name: "gongfu",
    address:"okexchainvaloper1w5zu7xxzfdx729elg2lu4rnltjsvzpdgp9x6xa"
  },
  {
    name: "icoin",
    address:"okexchainvaloper1fwvre7w2na66fq3k2wjy30rzp07c4fl94yqvt6"
  },
  {
    name: "jiqix",
    address:"okexchainvaloper1evazeyntpfr62avj65dwd6mcw9wvh24kgehuwy"
  },
  {
    name: "oklive",
    address:"okexchainvaloper1v5pvu4rkzc5axd6f7ngxa39je6d0lyujxv9sgu"
  },
  {
    name: "okusb",
    address:"okexchainvaloper14kpvn0zr75594rlrl66lw953mlkrq6qzys686x"
  },
  {
    name: "oxford",
    address:"okexchainvaloper1xaxvu9wxr8szym3aqdesvqq968y2tf5300clxz"
  },
  {
    name: "voyager",
    address:"okexchainvaloper1hw0y28hgzadpmjwa68sfukdp69pc68cnkweqym"
  },
  {
    name: "xduck",
    address:"okexchainvaloper1vsjcts3ga8dgf6nj2q7vmlrnu5en4cnedc8n76"
  },
];

function Alliance() {
  const getImgSource = (name) => {
    return require(`../images/node-logo/${name}.png`);
  };

  const AllianceItem = (name, address) => {
    return (
      <div key={name} className="p-4 lg:w-1/5 sm:w-1/2">
        <a href={`https://www.oklink.com/okexchain/address/${address}`} 
        target="__blank"
        className="rounded-md bg-white h-full flex flex-col items-center text-center border-2 border-light-blue-500 border-opacity-25 p-2">
          <img
            alt="team"
            className={`items-center  flex-shrink-0 rounded-lg w-auto h-24 object-cover object-center`}
            src={getImgSource(name)}
          />
        </a>
      </div>
    );
  };

  return (
    <section>
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">社区节点联盟</h2>
          </div>
        </div>
        <div className="flex flex-wrap -m-4 justify-center">
          {nodeList.map((node) => AllianceItem(node.name, node.address))}
        </div>
      </div>
    </section>
  );
}

export default Alliance;
