import React, { useContext, useState, useEffect } from "react";
import OkContext from "../context/OKContext";

import KeyStoreModal from "../components/KeyStoreModal";
import { ellipseAddress } from "../utils/utils";
import { getAddressFromLocal, getKeystoreDataFromLocal, clearLocalData } from "../utils/Okex";
import { createPopper } from "@popperjs/core";

const AccButton = () => {
  //   const { okAccount } = useContext(OkContext);

  const [showModal, setShowModal] = useState(false);

  const keystore = getKeystoreDataFromLocal();
  const address = getAddressFromLocal();

  const showImport = !keystore || !address;

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  // bg colors
  const color = "white";
  const bgColor = "bg-blueGray-700";


  // handle exit
  const hanldeExit = (e) => {

    e.preventDefault()

    // clear local storage data
    clearLocalData()

    // refresh page
    window.location.reload()
  }

  const dropdownButton = (address) => (
    <>
      <div className="flex flex-wrap">
        <div className="w-full sm:w-6/12 md:w-4/12 px-4">
          <div className="relative inline-flex align-middle w-full">
            <button
              className={
                "px-6 py-2 rounded-full text-white  bg-indigo-600 rhover:bg-indigo-700 w-full sm:w-auto sm:mb-0 " +
                bgColor
              }
              type="button"
              ref={btnDropdownRef}
              onClick={() => {
                dropdownPopoverShow
                  ? closeDropdownPopover()
                  : openDropdownPopover();
              }}
            >
              {color === "white" ? ellipseAddress(address, 8) : color + " Dropdown"}
            </button>
            <div
              ref={popoverDropdownRef}
              className={
                (dropdownPopoverShow ? "block " : "hidden ") +
                (color === "white" ? "bg-white " : bgColor + " ") +
                "text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
              }
              style={{ minWidth: "12rem" }}
            >
              <a
                href={`https://www.oklink.com/okexchain/address/${address}`}
                target="__blank"
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " +
                  (color === "white" ? " text-blueGray-700" : "text-white")
                }
                // onClick={(e) => e.preventDefault()}
              >
                OKLink 查看
              </a>
              {/* <a
                href="#pablo"
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " +
                  (color === "white" ? " text-blueGray-700" : "text-white")
                }
                onClick={(e) => e.preventDefault()}
              >
                Another action
              </a>
              <a
                href="#pablo"
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " +
                  (color === "white" ? " text-blueGray-700" : "text-white")
                }
                onClick={(e) => e.preventDefault()}
              >
                Something else here
              </a> */}
              <div className="h-0 my-2 border border-solid border-t-0 border-blueGray-800 opacity-25" />
              <a
                href="#pablo"
                className={
                  "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " +
                  (color === "white" ? " text-blueGray-700" : "text-white")
                }
                onClick={e => hanldeExit(e)}
              >
                退出
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      {showImport ? (
        <button
          className="px-6 py-2 rounded-full text-white bg-indigo-600 rhover:bg-indigo-700 w-full sm:w-auto sm:mb-0 sm:text-sm"
          onClick={() => setShowModal(true)}
        >
          导入keystore
        </button>
      ) : (
        // <button className="px-6 py-2 rounded-full text-white  bg-indigo-600 rhover:bg-indigo-700 w-full sm:w-auto sm:mb-0">
        //   {ellipseAddress(address, 8)}
        // </button>
        dropdownButton(address)
      )}
      <KeyStoreModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default AccButton;
