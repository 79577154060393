import React, { useState, useEffect, useMemo } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import OKContext from './context/OKContext'

import 'react-toastify/dist/ReactToastify.css';


function App() {

  const location = useLocation();
  const [okAccount, setOkAccount] = useState()
  const [isPrivatekeyOldAddress, setIsPrivatekeyOldAddress] = useState(false);

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  const initOkAccount = (acc, isPrivatekeyOld=false) => {
    setOkAccount(acc)
    setIsPrivatekeyOldAddress(isPrivatekeyOld)
    
  }

  const okContextValue = useMemo(() => ({
    okAccount, 
    isPrivatekeyOldAddress,
    initOkAccount
  }), [okAccount, isPrivatekeyOldAddress])

  return (
    <>
    <OKContext.Provider value={okContextValue}>
    <Switch>
        <Route exact path="/">
          <Home />
        </Route>
      </Switch>
    </OKContext.Provider>
    </>
  );
}

export default App;
