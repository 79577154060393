import React, { useRef, useState, useContext } from "react";
import ReactFileReader from "react-file-reader";
import OkContext from "../context/OKContext";

import {
  getPrivateKeyFromKeyStore,
  getAddressFromPrivateKey,
  getAccountFromPrivateKey,
  setKeystoreDataToLocal,
  setAddressToLocal,
} from "../utils/Okex";

import { toast } from "react-toastify";

export default function KeyStoreModal({ showModal, setShowModal }) {
  const { initOkAccount } = useContext(OkContext);
  const [keystoreFile, setKeystoreFile] = useState();
  const [keystoreContent, setKeystoreContent] = useState();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const refIsOldKeystore = useRef(null);


  const handleImport = (files) => {
    const f = files[0];
    console.log(f);
    setKeystoreFile(f);


    const fr = new FileReader();

    fr.onload = async (e) => {
      const data = e.target.result;
      // console.log("keystore", data);

      setKeystoreContent(data);
    };

    fr.readAsText(f);
  };

  const hanldeConfirm = async () => {

    const isOldPrivatekey = refIsOldKeystore.current.checked

    console.log('refIsOldKeystore', isOldPrivatekey)


    
    setLoading(true);

    try {
      const privateKey = await getPrivateKeyFromKeyStore(
        keystoreContent,
        password
      );

      // const privateKey = await getPrivateKeyFromMnemonic("", '60')

      // const privateKey = ""

      // console.log("privatekey", privateKey);

      // 如果能进行下去，代表验证成功

      // save keystore data to local storage
      setKeystoreDataToLocal(keystoreContent);


      // init ok account
      const okAcc = await getAccountFromPrivateKey(privateKey, isOldPrivatekey);

      // set ok account to context
      initOkAccount(okAcc, isOldPrivatekey);


      // const address = getAddressFromPrivateKey(privateKey);

      // set address to localstorage
      setAddressToLocal(okAcc.address);



      // finish
      // close modal
      setShowModal(false);

      // 登入钱包成功
      toast("登入钱包成功", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      const errMsg = err.toString();
      if (errMsg.indexOf("invalid password") !== -1) {
        console.log("invalid password");

        toast.error("密码错误，请重新输入", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (
        errMsg.indexOf("Cannot read property 'kdfparams' of undefined") !== -1
      ) {
        console.log("invalid keystore");

        toast.error("keystore 错误，请重新选择", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    setLoading(false);

    // save keystore to localstorage
    // localStorage.setItem("keystore", keystore);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold">导入 Keystore</h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  <ReactFileReader
                    fileTypes={[".json", ".txt"]}
                    multipleFiles={false}
                    handleFiles={handleImport}
                  >
                    <button className="h-10 px-5 m-2 text-indigo-100 transition-colors duration-150 bg-indigo-700 rounded-lg focus:shadow-outline hover:bg-indigo-800">
                      {!keystoreFile
                        ? "选择 keystore 文件"
                        : "重新选择 keystore 文件"}
                    </button>
                  </ReactFileReader>
                  {keystoreFile ? (
                    <div className="py-2 mb-2">已选择:{keystoreFile.name}</div>
                  ) : null}


                  {/* is old private key */}
                  <div className="flex items-center justify-center w-full mt-2 mb-2">
                    <label
                      htmlFor="toogleA"
                      className="flex items-center cursor-pointer"
                    >
                      <div className="relative">
                        <input
                          id="toogleA"
                          type="checkbox"
                          ref={refIsOldKeystore}
                          className="sr-only"
                        />
                        <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                        <div className="absolute w-6 h-6 transition bg-white rounded-full shadow dot -left-1 -top-1"></div>
                      </div>
                      <div className="ml-3 text-xs text-gray-700 font-xs">
                        来自旧的的 privatekey
                      </div>
                    </label>
                  </div>

                  {/* password input */}
                  {keystoreFile ? (
                    <div className="relative flex flex-wrap items-stretch w-full mb-3">
                      <input
                        type="password"
                        placeholder="keystore密码"
                        className="relative w-full px-3 py-3 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </div>
                  ) : null}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold uppercase transition-all duration-150 ease-linear outline-none background-transparent foc us:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    取消
                  </button>
                  <button
                    className="inline-flex items-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={hanldeConfirm}
                    disabled={loading ? "disabled" : false}
                  >
                    {loading ? (
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : null}
                    确定
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}
