import React, { useState, useContext } from "react";
import OkContext from "../context/OKContext";

import {
  getAddressFromLocal,
  defaultFee
} from "../utils/Okex";

import { toast } from "react-toastify";

export default function DepositModal({ showModal, setShowModal }) {
  const { okAccount, isPrivatekeyOldAddress } = useContext(OkContext);

  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const address = getAddressFromLocal()


  // 抵押 okt
  const hanldeConfirm = async () => {

    // check: amount <= balance

    if (okAccount && address) {

      setLoading(true);

      const msg = [
        {
          type: "okexchain/staking/MsgDeposit",
          value: {
            delegator_address: address,
            quantity: {
              amount: okAccount.formatNumber(amount),
              denom: "okt",
            },
          },
        },
      ];

      console.log("deposit okAccount", await okAccount.getAccount());
      console.log("deposit msg", msg);

      const memo = "";
      const fee = defaultFee;

      const signedTx = await okAccount.buildTransaction(
        msg,
        msg,
        memo,
        fee,
        null,
        isPrivatekeyOldAddress
      );
      console.log("deposit signed tx", signedTx);
      const res = await okAccount.sendTransaction(signedTx);
      console.log("deposit res", res);

      // close modal
      setShowModal(false)

      // show success toast
      toast('抵押OKT成功，现在可以点击投票了', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      
    } else {
      console.log("ok account is null");
      // open type keystore password modal
      // setPasswordShowModal(true);

      toast.error('请先登陆', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

    setLoading(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
                  <h3 className="text-xl font-semibold">抵押 OKT</h3>
                  <button
                    className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="block w-6 h-6 text-2xl text-black bg-transparent outline-none opacity-5 focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  {/* password input */}
                    <div className="relative flex flex-wrap items-stretch w-full mb-3">
                      <input
                        type="text"
                        placeholder="请输入OKT数量"
                        className="relative w-full px-3 py-3 text-sm bg-white border-0 rounded shadow outline-none placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                      />
                    </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
                  <button
                    className="px-6 py-2 mb-1 mr-1 text-sm font-bold uppercase transition-all duration-150 ease-linear outline-none background-transparent foc us:outline-none"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    取消
                  </button>
                  <button
                    className="inline-flex items-center px-6 py-3 mb-1 mr-1 text-sm font-bold text-white transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                    type="button"
                    onClick={hanldeConfirm}
                    disabled={loading ? "disabled" : false}
                  >
                    {loading ? (
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : null}
                    确定
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </>
  );
}
