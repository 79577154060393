import React, { useState, useContext, useEffect } from "react";
import OkContext from "../context/OKContext";
import {
  getOKTbalanceFromAddress,
  getAddressFromLocal,
  defaultFee,
  validators,
  validatorsCommunity,
  originValidators,
} from "../utils/Okex";
import { equalsIgnoreOrder } from "../utils/utils";
import { getDelegatorInfo, getAllValidators } from "../utils/api";
import PasswordModal from "../components/PasswordModal";
import DepositModal from "../components/DepositModal";
import WithdrawModal from "../components/WithdrawModal";
import { toast } from "react-toastify";
import BigNumber from "bignumber.js";
import _ from "lodash";

function Dashboard() {
  const { okAccount, isPrivatekeyOldAddress } = useContext(OkContext);

  // password modal show
  const [showPasswordModal, setPasswordShowModal] = useState(false);

  // deposit modal show
  const [showDepositdModal, setDepositShowModal] = useState(false);

  // withdraw modal show
  const [showWithdrawdModal, setWithdrawShowModal] = useState(false);

  // ok account okt balance
  const [balance, setBalance] = useState(0);

  // delegated okt amount
  const [delegatedAmount, setDelegateAmount] = useState(0);

  // 投票权重
  const [sharesAmount, setSharesAmount] = useState(0);

  // 是否是投给节点联盟
  const [isVoted, setIsVoted] = useState(false);

  // 节点联盟的得票率
  const [allianceShareRate, setAllianceShareRate] = useState(0);

  // 是否已经投过票
  // const [votedAddress, setVotedAddress] = useState();

  // 各种操作 loading status
  const [loading, setLoading] = useState(false);

  const address = getAddressFromLocal();

  async function getBalance(address) {
    try {
      const bal = await getOKTbalanceFromAddress(address);
      setBalance(bal);
    } catch (err) {
      console.log("get balance error");
    }
  }

  async function getDelegatedAmount(address) {
    try {
      const res = await getDelegatorInfo(address);
      // console.log("getDelegatorInfo", res.data);
      if (res.data) {
        if (res.data.tokens) {
          setDelegateAmount(Number(res.data.tokens));
        }

        if (res.data.shares) {
          setSharesAmount(Number(res.data.shares));
        }

        if (res.data.validator_address) {
          // setVotedAddress(res.data.validator_address);
          console.log("validator_address", res.data.validator_address);
          const isVotedToOldValidators = equalsIgnoreOrder(
            originValidators,
            res.data.validator_address
          );

          const isVotedToNewValidators = equalsIgnoreOrder(
            validators,
            res.data.validator_address
          );

          setIsVoted(isVotedToOldValidators || isVotedToNewValidators);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (address) {
      getBalance(address);
      getDelegatedAmount(address);
    }
  }, [address]);

  useEffect(() => {
    async function getDashboardData() {
      const res = await getAllValidators();

      // 所以节点的总票数
      let allShares = new BigNumber(0);

      // 节点联盟总票数
      let allianceSharesTotal = new BigNumber(0);
      if (res.data) {
        console.log("getAllValidators", res.data);

        for (let i = 0; i < res.data.length; i++) {
          let shares = new BigNumber(res.data[i].delegator_shares);

          allShares = allShares.plus(shares);

          // if current validator in alliance nodes
          if (_.indexOf(validatorsCommunity, res.data[i].operator_address) !== -1) {
            allianceSharesTotal = allianceSharesTotal.plus(shares);
          }
        }

        // console.log('allShares', allShares.toString())
        // console.log('allianceSharesTotal', allianceSharesTotal.toString())

        const shareRate = !allShares.isZero()
          ? allianceSharesTotal.div(allShares)
          : new BigNumber(0);

        // console.log('shareRate', shareRate.toString())

        // 节点联盟得票率 (%)
        setAllianceShareRate(Number(shareRate.toString()) * 100);
      }
    }

    getDashboardData();
  }, []);

  // 抵押 okt
  const handleDeposit = async () => {
    if (okAccount) {
      setDepositShowModal(true);
    } else {
      console.log("ok account is null");
      // open type keystore password modal
      setPasswordShowModal(true);
    }
  };

  // 退出投票，取回 okt
  const handleWithdraw = async () => {
    if (okAccount) {
      setWithdrawShowModal(true);
    } else {
      console.log("ok account is null");
      // open type keystore password modal
      setPasswordShowModal(true);
    }
  };

  // click 投票给我们
  const hanldeVote = async () => {
    if (okAccount && address) {
      setLoading(true);

      const addShareMsg = [
        {
          type: "okexchain/staking/MsgAddShares",
          value: {
            delegator_address: address,
            validator_addresses: validators,
          },
        },
      ];

      // const bindProxyMsg = [
      //   {
      //     type: "okexchain/staking/MsgBindProxy",
      //     value: {
      //       delegator_address: address,
      //       proxy_address: "okexchain1dl30pugqp6ms787jfm4a3ru89gs9t0r632a0qp",
      //     },
      //   },
      // ];

      // 判断如果没有 add share 过，就用 bind prorxy, 否则还是 add share

      // 取消 Proxy 方式
      // if (votedAddress) {
      //   msg = addShareMsg;
      // } else {
      //   msg = bindProxyMsg;
      // }

      const msg = addShareMsg;

      const memo = "";
      const fee = defaultFee;

      const signedTx = await okAccount.buildTransaction(
        msg,
        msg,
        memo,
        fee,
        null,
        isPrivatekeyOldAddress
      );

      console.log("signed tx", signedTx);

      // return;

      const res = await okAccount.sendTransaction(signedTx);
      console.log("res", res);

      if (res.result.code === -1) {
        toast("投票失败" + res.result.msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // show success toast
        toast("投票成功", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      console.log("ok account is null");

      // open type keystore password modal
      setPasswordShowModal(true);

      // toast.error('请先登陆', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   });
    }

    setLoading(false);
  };

  // const handleTransfer = async () => {
  //   if (okAccount && address) {
  //     const res = await okAccount.sendSendTransaction(
  //       "ex175ld9yd8gcp0lwe6q2vspypnwfflawfeptyr24",
  //       okAccount.formatNumber(0.001),
  //       "okt",
  //       "hello world"
  //     );
  //     console.log(JSON.stringify(res));
  //   } else {
  //     console.log("ok account is null");

  //     // open type keystore password modal
  //     setPasswordShowModal(true);
  //   }
  // };

  return (
    <section className="text-gray-600 bg-gray-100 body-font">
      <div className="container px-5 py-24 mx-auto">
        {/* <div className="flex flex-col w-full mb-20 text-center">
          <h1 className="mb-4 text-2xl font-medium text-gray-900 sm:text-3xl title-font">
            Master Cleanse Reliac Heirloom
          </h1>
          <p className="mx-auto text-base leading-relaxed lg:w-2/3">
            Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical
            gentrify, subway tile poke farm-to-table. Franzen you probably
            haven't heard of them man bun deep jianbing selfies heirloom prism
            food truck ugh squid celiac humblebrag.
          </p>
        </div> */}
        <div className="flex flex-wrap -m-4 text-center">
          <div className="w-full p-4 md:w-1/4 sm:w-1/2">
            <div className="flex flex-col justify-center h-full px-4 py-6 border-2 border-gray-200 rounded-lg">
              {/* <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="inline-block w-12 h-12 mb-3 text-indigo-500"
                viewBox="0 0 24 24"
              >
                <path d="M8 17l4 4 4-4m-4-5v9"></path>
                <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
              </svg> */}
              <h2 className="text-3xl font-medium text-gray-900 title-font">
                {balance ? balance : "--"}
              </h2>
              <p className="leading-relaxed">钱包OKT余额</p>

              {balance ? (
                <div className="mt-4">
                  <button
                    className="w-full mb-4 text-white bg-blue-600 btn hover:bg-blue-700 sm:w-auto sm:mb-0"
                    onClick={handleDeposit}
                  >
                    抵押 OKT
                  </button>

                  {/* <button
                    className="w-full mb-4 text-white bg-blue-600 btn hover:bg-blue-700 sm:w-auto sm:mb-0"
                    onClick={handleTransfer}
                  >
                    转账 OKT
                  </button> */}
                </div>
              ) : null}
            </div>
          </div>

          <div className="w-full p-4 md:w-1/4 sm:w-1/2">
            <div className="flex flex-col justify-center h-full px-4 py-6 border-2 border-gray-200 rounded-lg">
              {/* <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="inline-block w-12 h-12 mb-3 text-indigo-500"
                viewBox="0 0 24 24"
              >
                <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
              </svg> */}
              <h2 className="text-3xl font-medium text-gray-900 title-font">
                {isVoted ? delegatedAmount.toLocaleString() : "--"}
              </h2>
              <p className="leading-relaxed">我投给节点联盟的OKT</p>

              {address ? (
                <div className="mt-4">
                  <button
                    className="inline-flex w-full mb-4 text-2xl text-white bg-indigo-600 btn rhover:bg-indigo-700 sm:w-auto sm:mb-0"
                    onClick={hanldeVote}
                    disabled={loading ? "disabled" : false}
                  >
                    {loading ? (
                      <svg
                        className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : null}
                    投票给我们
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <div className="w-full p-4 md:w-1/4 sm:w-1/2">
            <div className="flex flex-col justify-center h-full px-4 py-6 border-2 border-gray-200 rounded-lg">
              {/* <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="inline-block w-12 h-12 mb-3 text-indigo-500"
                viewBox="0 0 24 24"
              >
                <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
              </svg> */}
              <h2 className="text-3xl font-medium text-gray-900 title-font">
                {address ? delegatedAmount : "--"}
              </h2>
              <p className="leading-relaxed">我抵押的 OKT</p>

              {delegatedAmount ? (
                <div className="mt-4">
                  <button
                    className="inline-flex text-gray-700 bg-gray-200 border-0 btn focus:outline-none hover:bg-gray-200"
                    onClick={handleWithdraw}
                  >
                    退出投票
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <div className="w-full p-4 md:w-1/4 sm:w-1/2">
            <div className="flex flex-col justify-center h-full px-4 py-6 border-2 border-gray-200 rounded-lg">
              {/* <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="inline-block w-12 h-12 mb-3 text-indigo-500"
                viewBox="0 0 24 24"
              >
                <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
              </svg> */}
              <h2 className="text-3xl font-medium text-gray-900 title-font">
                {allianceShareRate ? allianceShareRate.toLocaleString() : "--"}%
              </h2>
              <p className="leading-relaxed">节点联盟得票率</p>
            </div>
          </div>

          {/* <div className="w-full p-4 md:w-1/4 sm:w-1/2">
            <div className="px-4 py-6 border-2 border-gray-200 rounded-lg">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="inline-block w-12 h-12 mb-3 text-indigo-500"
                viewBox="0 0 24 24"
              >
                <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
              </svg>
              <h2 className="text-3xl font-medium text-gray-900 title-font">46</h2>
              <p className="leading-relaxed">Places</p>
            </div>
          </div> */}
        </div>
      </div>
      <PasswordModal
        showModal={showPasswordModal}
        setShowModal={setPasswordShowModal}
      />
      <DepositModal
        showModal={showDepositdModal}
        setShowModal={setDepositShowModal}
      />

      <WithdrawModal
        showModal={showWithdrawdModal}
        setShowModal={setWithdrawShowModal}
      />
    </section>
  );
}

export default Dashboard;
