const axios = require("axios");

// Set config defaults when creating the instance
// const instance = axios.create({
//   baseURL: "https://okex-rpc.nebulas.io/exchain/v1",
// });

const instance = axios.create({
  baseURL: "https://exchainrpc.okex.org/okexchain/v1",
});

export const getAllValidators = () => {
  return instance.get("/staking/validators?status=all");
};

export const getDelegatorInfo = (address) => {
  return instance.get("/staking/delegators/" + address);
};
